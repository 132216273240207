import { ListNoBullets } from '@app/components/atm.list-no-bullets/ListNoBullets.component'
import { FieldsWrapper } from '@app/components/atm.wrapper/wrapper.component'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import { PaginationContainer } from '@app/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from '@app/components/legacy/pagination-wrapper.component'
import NumberOfResults from '@app/components/mol.number-of-results/number-of-results.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { Col, Divisor, Grid, Row, Separator } from 'atomic'
import { getListJsonLd } from '@root/src/components/legacy/mol.seo/build-list-json-ld'
import { FuseSearch } from '@root/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from '@root/src/components/mol.local-search/fuse-search.utils'
import { MediaCell, MediaCellProps } from '@root/src/components/mol.media-cell/media-cell.component'
import { CosmicjsInformacoesEstaticasMetadataSeo } from '@root/src/data/graphql/graphql-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import React from 'react'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'

export interface NewsPageProps {
  list: MediaCellProps[]
  location?: Location
  siteUrl: string
  seoData: CosmicjsInformacoesEstaticasMetadataSeo
}

export const NewsPage: React.FunctionComponent<NewsPageProps> = props => {
  const indexParams: IndexParams<MediaCellProps> = {
    data: props.list,
    keys: [
      {
        name: 'title',
        weight: 0.8
      },
      { name: 'content', weight: 0.2 }
    ]
  }
  const [newsList, setNewsList] = React.useState(props.list)
  const numberOfItems = newsList.length
  const onResult = list => {
    if (list) {
      setNewsList(list)
    }
  }

  const siteUrl = props.siteUrl
  return (
    <>
      <Grid>
        <TitleWithBreadcrumbRow title="Notícias" />
        <Row mb>
          <Col xs={12} lg={12}>
            <FieldsWrapper>
              <FuseSearch
                id="input-news-page-search"
                indexParams={indexParams}
                onResultChange={onResult}
                placeholder={'Busque por uma notícia'}
              />
            </FieldsWrapper>
            <Separator />
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row mb>
          <Col xs={12} lg={12}>
            {newsList.length > 0 ? (
              <PaginationContainer>
                {paginationProps => {
                  const startIndex =
                    (paginationProps.pagination.current - 1) * paginationProps.limit.current
                  const endIndex =
                    paginationProps.pagination.current * paginationProps.limit.current
                  const pageList = (newsList || []).slice(startIndex, endIndex)
                  return (
                    <>
                      <SEO
                        jsonld={getListJsonLd(
                          pageList.map(item => ({ url: `${siteUrl}${item.to}` }))
                        )}
                        socialMedia={{
                          canonicalUrl: getCanonicalUrl(siteUrl, appPaths.news.path),
                          title: props.seoData.titulo,
                          image: props.seoData.imagem.url,
                          imageAlt: props.seoData.imagemAlternativa,
                          description: props.seoData.descricao
                        }}
                      />
                      <Row mt mb>
                        <Col>
                          <NumberOfResults numberOfItems={numberOfItems} />
                          <Separator />
                        </Col>
                      </Row>
                      <Row center="xs" mb>
                        <Col xs={12}>
                          <ListNoBullets id="list-news-page">
                            {pageList &&
                              pageList.map((item, index) => (
                                <React.Fragment key={item.title}>
                                  <Separator />
                                  <MediaCell id={`news-page-${index}`} {...item} />
                                  <Separator />
                                  <Divisor />
                                </React.Fragment>
                              ))}
                          </ListNoBullets>
                        </Col>
                      </Row>
                      <PaginationRow
                        pagination={{ ...paginationProps.pagination, total: newsList.length }}
                        limit={paginationProps.limit}
                      />
                    </>
                  )
                }}
              </PaginationContainer>
            ) : (
              <Placeholder
                icon={<SvgBuscaSemResultado />}
                title={'Nenhum resultado encontrado'}
                description="Tente buscar outro termo ou ligue para nossa central de atendimento."
              />
            )}
          </Col>
        </Row>
      </Grid>
    </>
  )
}
