import { Spacing, H3 } from 'atomic'
import styled from 'styled-components'

export const MediaCellText = styled(H3)``

export const MediaCellStyled = styled.li`
  margin-bottom: ${Spacing.Large};
  :hover {
    ${MediaCellText} {
      text-decoration: underline;
    }
  }
`

export const MediaCellTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const MediaCellImgWrapper = styled.div`
  float: right;
  margin-right: ${Spacing.XLarge};
`
export const MediaCellBottomTextWrapper = styled.div`
  margin-top: auto;
`
