import { BodySecondary, Button, Col, Row, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { parseDateToString, parseToDate } from '@root/src/utils/date-parser/date-parser'
import { Link } from 'gatsby'
import * as React from 'react'
import { BorderRoundWrapper } from '../atm.wrapper/wrapper.component'
import {
  MediaCellBottomTextWrapper,
  MediaCellStyled,
  MediaCellText,
  MediaCellTextWrapper
} from './media-cell.component.style'

export interface MediaCellProps {
  imgSrc: string
  imgAlt: string
  title: string
  content: string
  autor?: string
  date?: string
  to: string
  small?: boolean
  readMoreButton: string
  id: string
}

export const MediaCell: React.FunctionComponent<MediaCellProps> = ({ id, ...props }) => (
  <MediaCellStyled id={id}>
    <Link id={`anchor-${id}`} to={props.to}>
      <Row>
        <Col xs={12} sm={4} md={3}>
          <BorderRoundWrapper>
            <LazyLoadImage src={props.imgSrc} aspectRatio={160 / 255} fitWidth alt={props.imgAlt} />
          </BorderRoundWrapper>

          <MatchMedia defaultMinWidth={`48em`}>{md => <>{md && <Separator />}</>}</MatchMedia>
        </Col>
        <Col xs={12} smOffset={1} sm={7} mdOffset={1} md={8}>
          <MediaCellTextWrapper>
            <MediaCellText cell>{props.title}</MediaCellText>
            <BodySecondary>{props.content}</BodySecondary>

            <Button id="button-media-cell" kind="link" to={props.to}>
              {props.readMoreButton}
            </Button>
            <MediaCellBottomTextWrapper>
              <Separator />
              {props.autor && (
                <div>
                  <BodySecondary>{props.autor}</BodySecondary>
                </div>
              )}
              {props.date && (
                <div>
                  <BodySecondary>{parseDateToString(parseToDate(props.date))}</BodySecondary>
                </div>
              )}
            </MediaCellBottomTextWrapper>
          </MediaCellTextWrapper>
        </Col>
      </Row>
    </Link>
  </MediaCellStyled>
)
