import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { PageProps } from '@app/utils/local-types'
import { getClientAndDoctorUrl } from 'utils/url'
import { MediaCellProps } from '@root/src/components/mol.media-cell/media-cell.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { NewsPage } from '@root/src/modules/news/component/news-page.component'
import { graphql } from 'gatsby'
import React from 'react'

export interface NewsTemplateProps {
  list: MediaCellProps[]
  location?: Location
  siteUrl: string
}

class NewsTemplate extends React.Component<PageProps<NewsTemplateProps>> {
  render() {
    const newsList = this.props.pageContext.list
    const [clientPath, doctorPath] = getClientAndDoctorUrl(this.props.location)
    return (
      <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
        <IndexLayout location={this.props.location}>
          <NewsPage
            list={newsList}
            location={this.props.location}
            siteUrl={this.props.pageContext.siteUrl}
            seoData={this.props.data.cosmicjsInformacoesEstaticas.metadata.seo}
          />
        </IndexLayout>
      </ClientDoctorSelectContext.Provider>
    )
  }
}

export default NewsTemplate

export const query = graphql`
  query NoticiasLista {
    cosmicjsInformacoesEstaticas(slug: { eq: "noticias-lista" }) {
      metadata {
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
  }
`
